import logo from '@assets/logoSL.png';

function App() {
  return (
    <div className="mx-auto h-screen">
      <div className="relative overflow-hidden bg-teal-500 px-6 py-10 shadow-xl sm:px-12 sm:py-20 h-screen">
        <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
          <svg
            className="absolute inset-0 h-full w-full"
            preserveAspectRatio="xMidYMid slice"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1463 360">
            <path
              className="text-teal-400 text-opacity-40"
              fill="currentColor"
              d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
            />
            <path
              className="text-teal-600 text-opacity-40"
              fill="currentColor"
              d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
            />
          </svg>
        </div>
        <div className="relative">
          <div className="sm:text-center">
            <img src={logo} className="h-20 mx-auto mb-6 brightness-0 invert" />
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Thoody Consulting
            </h2>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-teal-100">
              Notre site se refait une beauté, nous reviendrons prochainement.
            </p>
            <p className="mx-auto mt-6 max-w-2xl text-lg text-teal-100">
              N&apos;hésitez pas à nous contacter au +377.99.90.12.75 ou à l&apos;adresse
              contact@thoody.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// const styles = {
//   font: {
//     fontFamily: 'Lato, sans-serif'
//   }
// };

export default App;
